import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Utils from '../../utils';
import items from './items.json';
import $ from '../../styles/global';
import CloseIcon from '../../assets/icons/close.svg';

const Background = styled.div`
  position: fixed;
  z-index: 1;
  background-color: rgba(3, 6, 12, 0.67);
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  transition: all 0.25s ${$.easingFn.standard};

  &.active {
    pointer-events: initial;
    opacity: 1;
    > * {
      transform: translateX(0) translateY(0);
    }
  }

  ${$.device.desktop} {
    display: none;
  }
`;

const InnerContainer = styled.div`
  box-shadow: ${$.dropShadow.normal};
  background-color: ${$.color.white};
  width: calc(
    100% - ${$.layout().margin2 * 2}px - ${$.layout().padding2 * 2}px
  );
  margin: 10px ${$.layout().margin2}px;
  border-radius: 10px;
  box-shadow: ${$.dropShadow.normal};
  padding: ${$.layout().padding2}px ${$.layout().padding2}px 80px
    ${$.layout().padding2}px;
  transform: translateX(15px) translateY(-1.5em);
  transition: transform 0.25s ${$.easingFn.standard},
    opacity 0.2s ${$.easingFn.standard};
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    font-size: 22px;
    font-family: Lato Bold;
    text-decoration: none;
    color: ${$.color.base};
    &:visited {
      color: ${$.color.base};
    }
    &:not(:last-child) {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
`;

const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: ${$.layout().margin4}px;

  & > svg {
    fill: ${$.color.base};
    width: 36px;
    height: 36px;
  }
`;

const links = Utils.addKeys(items);

const MobileNav = ({ show, setShowMobile }) => {
  const containerRef = useRef(null);

  Utils.useOutsideClick([containerRef], () => {
    setShowMobile(false);
  });

  return (
    <Background className={show ? 'active' : ''}>
      <InnerContainer ref={containerRef}>
        <CloseIconContainer
          onClick={() => {
            setShowMobile(false);
          }}
        >
          <CloseIcon />
        </CloseIconContainer>
        <NavLinks>
          {links.map(({ key, name, link }) => (
            <Link
              to={link}
              key={key}
              onClick={() => {
                setShowMobile(false);
              }}
            >
              {name}
            </Link>
          ))}
        </NavLinks>
      </InnerContainer>
    </Background>
  );
};

MobileNav.defaultProps = {
  show: false,
  setShowMobile: () => {},
};

MobileNav.propTypes = {
  show: PropTypes.bool,
  setShowMobile: PropTypes.func,
};

export default MobileNav;
