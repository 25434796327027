import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import $ from '../styles/global';
import Footer from './Footer';
import Navbar from './Navbar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Body = styled.div`
  flex: 1 0 auto;
  position: relative;
`;

const InnerContainer = styled.div`
  padding-top: 86px;
  & > section {
    width: 100%;

    & > div {
      width: calc(100% - ${$.layout().margin3 * 2}px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${$.device.desktop} {
    & > section > div {
      width: 970px;
    }
  }
`;

const LayoutComponent = ({ children }) => (
  <Container>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      />
    </Helmet>
    <Navbar />
    <Body>
      <InnerContainer>{children}</InnerContainer>
    </Body>
    <Footer />
  </Container>
);

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
