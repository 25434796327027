import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import FacebookIcon from '../assets/icons/facebook.svg';
import EmailIcon from '../assets/icons/email.svg';
import PhoneIcon from '../assets/icons/phone.svg';
import FaxIcon from '../assets/icons/fax.svg';
import $ from '../styles/global';
import items from './Navbar/items.json';
import Utils from '../utils';

const Container = styled.div`
  flex-shrink: 0;
  background-color: ${$.color.base};

  ${$.device.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const InnerContainer = styled.div`
  color: ${$.color.white};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 24%;
    &:first-child {
      width: 40%;
    }
  }

  ${$.device.desktop} {
    padding: 80px ${$.layout().padding4}px;
    width: 1100px;
  }

  ${$.device.tablet} {
    padding: 60px ${$.layout().padding4}px;
  }

  ${$.device.mobile} {
    padding: 40px ${$.layout().padding4}px;
    flex-direction: column;
    & > *,
    & > *:first-child {
      width: 100%;
    }

    & > *:not(:last-child) {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
`;

const CopyrightRow = styled.div`
  flex: 0 0 100%;
  font-size: 14px;
  margin-top: ${$.layout().margin4}px;

  ${$.device.mobile} {
    margin-top: 0;
  }
`;

const Column = styled.div``;

const ColumnTitle = styled.h3`
  font-size: 30px;
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin3}px;

  ${$.device.tablet} {
    font-size: 24px;
  }

  ${$.device.mobile} {
    font-size: 24px;
  }
`;

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 15px;
  line-height: 1.5em;

  & p:not(:last-child) {
    margin-bottom: ${$.layout().margin3}px;
  }
`;

const NavLink = styled(Link)`
  margin-bottom: ${$.layout().margin2}px;
  text-decoration: none;
  color: ${$.color.white};
  font-size: 17px;
  word-break: break-all;

  &:visited {
    color: ${$.color.white};
  }

  &:hover {
    text-decoration: underline;
  }

  ${$.device.tablet} {
    font-size: 16px;
  }

  ${$.device.mobile} {
    font-size: 15px;
  }
`;

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin-bottom: ${$.layout().margin2}px;
  word-break: break-all;

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${$.color.white};
    &:visited {
      color: ${$.color.white};
    }
    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    fill: ${$.color.white};
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const links = Utils.addKeys(items);

const Footer = () => (
  <Container>
    <InnerContainer>
      <Column>
        <ColumnTitle>Zenn HRB Sdn Bhd</ColumnTitle>
        <ColumnContent>
          <p>
            Zenn HRB is a company focused on quality concrete poles and concrete
            products. All of our products conform to the Japanese Industrial
            Standards (JIS A5309) which requires strict adherence to correct
            quality.
          </p>
          <p>
            We remain focused on conforming to industry standards, as well as
            continuing our stellar work we started since 1985.
          </p>
        </ColumnContent>
      </Column>
      <Column>
        <ColumnTitle>Navigate</ColumnTitle>
        <ColumnContent>
          {links.map(({ key, name, link }) => (
            <NavLink to={link} key={key}>
              {name}
            </NavLink>
          ))}
        </ColumnContent>
      </Column>
      <Column>
        <ColumnTitle>Stay Connected</ColumnTitle>
        <ColumnContent>
          <SocialRow>
            <FacebookIcon />
            <a
              href="https://www.facebook.com/ZENNHRBSB/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook @ZENNHRBSB
            </a>
          </SocialRow>
          <SocialRow>
            <EmailIcon />
            <a
              href="mailto:zennhrbsb@yahoo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              zennhrbsb@yahoo.com
            </a>
          </SocialRow>
          <SocialRow>
            <PhoneIcon />
            <div>
              <a
                href="tel:6053229022"
                target="_blank"
                rel="noopener noreferrer"
              >
                +605 - 322 9022
              </a>
              <a
                href="tel:6053223022"
                target="_blank"
                rel="noopener noreferrer"
              >
                +605 - 322 3022
              </a>
            </div>
          </SocialRow>
          <SocialRow>
            <FaxIcon />
            <a href="tel:6053225022" target="_blank" rel="noopener noreferrer">
              +605 - 322 5022
            </a>
          </SocialRow>
        </ColumnContent>
      </Column>
      <CopyrightRow>
        Copyright &copy; Zenn HRB Sdn Bhd (710220-U). All Rights Reserved.
      </CopyrightRow>
    </InnerContainer>
  </Container>
);

export default Footer;
