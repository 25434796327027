export default {
  color: {
    white: '#FFFFFF',
    gray1: '#424B54',
    black: '#000000',
    base: '#424B54',
    red: '#860000',
  },
  easingFn: {
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
    decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  baseNum: 8,
  layout() {
    return {
      margin5: this.baseNum * 16,
      margin4: this.baseNum * 8,
      margin3: this.baseNum * 4,
      margin2: this.baseNum * 2,
      margin1: this.baseNum,
      padding5: this.baseNum * 8,
      padding4: this.baseNum * 4,
      padding3: this.baseNum * 3,
      padding2: this.baseNum * 2,
      padding1: this.baseNum,
    };
  },
  border() {
    return {
      radius1: this.baseNum * 4,
      radius2: this.baseNum * 2,
      radius3: this.baseNum,
    };
  },
  ellipsis: `
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  dropShadow: {
    normal: '0px 3px 8px rgba(0, 0, 0, 0.15)',
    repressed: '0px 8px 12px rgba(0, 0, 0, 0.15)',
  },
  device: {
    mobile: '@media only screen and (max-width: 767px)',
    tablet: '@media only screen and (min-width: 768px) and (max-width: 1199px)',
    desktop: '@media only screen and (min-width: 1200px)',
  },
  br: `br {
    content: "";
    display: block;
    margin: 0.75em 0;
  }`,
};
