import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import $ from '../../styles/global';
import LogoIcon from '../../assets/icons/logo.svg';
import Utils from '../../utils';
import items from './items.json';
import MobileNav from './MobileNav';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${$.color.white};
  position: fixed;
  z-index: 1;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${$.device.desktop} {
    width: 1100px;
    padding: 25px 0;
  }

  ${$.device.tablet} {
    width: calc(100% - ${$.layout().padding4 * 2}px);
    padding: 25px ${$.layout().padding4}px;
  }

  ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding4 * 2}px);
    padding: 25px ${$.layout().padding4}px;
  }
`;

const Logo = styled.div`
  width: 135px;

  & > * {
    width: 100%;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: ${$.layout().margin3}px;
  }

  ${$.device.mobile} {
    display: none;
  }

  ${$.device.tablet} {
    display: none;
  }
`;

const AnimatedLink = styled(Link)`
  text-decoration: none;
  color: ${$.color.base};
  font-size: 15px;
  font-family: Lato Bold;

  &:visited {
    color: ${$.color.base};
  }

  & div {
    width: 0;
    height: 2px;
    background-color: ${$.color.base};
    transition: width 0.25s ease;
  }

  &:hover {
    & div {
      width: 100%;
    }
  }
`;

const Burger = styled.div`
  width: 24px;
  height: 20px;

  & > * {
    height: 4px;
    width: 100%;
    background-color: ${$.color.base};
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  ${$.device.desktop} {
    display: none;
  }
`;

const links = Utils.addKeys(items);

const Navbar = () => {
  const [showMobile, setShowMobile] = useState(false);

  return (
    <Container>
      <InnerContainer>
        <Link to="/">
          <Logo>
            <LogoIcon />
          </Logo>
        </Link>
        <Burger
          onClick={() => {
            setShowMobile((prev) => !prev);
          }}
        >
          <div />
          <div />
          <div />
        </Burger>
        <NavContainer>
          {links.map(({ key, link, name }) => (
            <AnimatedLink key={key} to={link}>
              <span>{name}</span>
              <div />
            </AnimatedLink>
          ))}
        </NavContainer>
        <MobileNav show={showMobile} setShowMobile={setShowMobile} />
      </InnerContainer>
    </Container>
  );
};

export default Navbar;
